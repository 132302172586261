<template>
  <div>
    <b-card no-body class="card-accent-primary" header-tag="header" footer-tag="footer">
      <w-top-menu :p_menuData="d_menuData"></w-top-menu>
      <template v-if="selected_general_tab === 'request_table'">
        <div>
          <template v-if="d_totalLogCount > d_logResultPageData['perpage']">
            <b-pagination size="sm" aria-controls="d_requestListTable" v-model="d_logResultPageData['current']" :total-rows="d_totalLogCount" :per-page="d_logResultPageData['perpage']"></b-pagination>
          </template>
          <div id="case_table" :style="f_dataSetStyle(300)" tabindex="0">
            <w-table key="data_table" :p_dataset="d_requestList" :p_columnList="d_requestListColumns" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :p_tablePagination="d_logResultPageData" :f_selectCell="f_selectCell" :f_dblClickToCell="f_showWTableCellData"></w-table>
          </div>
        </div>
      </template>
      <template v-if="selected_general_tab === 'request_count'">
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Sayısal
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col sm="12" md="4" v-for="(chart, chart_ind) in d_requestCountCharts" :key="'d_requestCountCharts_' + chart_ind + '_1'">
                  <div><strong>{{ chart.name }}</strong></div>
                  <b-row v-for="(lbl, lbl_ind) in chart.labels" :key="'chart.labels_' + lbl_ind" style="margin-left: 10px;">
                    <b-col cols="12">
                      {{ lbl }} : {{ chart.datasets[0].data[lbl_ind] }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <template v-if="selected_general_tab === 'request_chart'">
        <b-row style="padding: 3px;">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Grafiksel
              </b-card-header>
              <b-row style="margin: 0px;" v-for="(chart, chart_ind) in d_requestCountCharts" :key="'d_requestCountCharts_' + chart_ind + '_2'">
                <b-col sm="12" md="12">
                  <div v-if="d_showChartView" class="chart-wrapper" style="height: 500px;">
                    <template v-if="chart.type ==='line'">
                      <line-view :chart_data="chart" :style="'height:' + chartSize.height.toString() +'px;'" :height="chartSize.height + 'px;'" :width="chartSize.width + 'px;'" />
                    </template>
                    <template v-if="chart.type ==='bar'">
                      <bar-view :chart_data="chart" :style="'height:' + chartSize.height.toString() +'px;'" :height="chartSize.height + 'px;'" :width="chartSize.width + 'px;'" />
                    </template>
                    <template v-if="chart.type ==='scatter'">
                      <scatter-view :chart_data="chart" :style="'height:' + chartSize.height.toString() +'px;'" :height="chartSize.height + 'px;'" :width="chartSize.width + 'px;'" />
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-modal v-if="d_showSearchSettings" v-model="d_showSearchSettings" size="md" title="Arama Ayarları" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Başlangıç Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_searchingData.starting_at" v-model="d_searchingData.starting_at"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Bitiş Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_searchingData.ending_at" v-model="d_searchingData.ending_at"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="margin-top:25px">
        <b-col cols="4">
          Log Türü Filtreleme:
        </b-col>
        <b-col cols="8">
          <b-form-checkbox-group v-model="d_searchingData.user_type" switches>
            <b-form-checkbox id="user" value="user">Kullanıcı</b-form-checkbox>
            <b-form-checkbox id="not_user" value="not_user">Misafir</b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showTextFilter" v-model="d_showTextFilter" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="4">
          Arama İfadesi
        </b-col>
        <b-col cols="8">
          <b-input type="text" id="d_searchText" placeholder="Arama İfadesi Giriniz" v-model="d_searchText"></b-input>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showWTableCellData.show" v-model="d_showWTableCellData.show" size="md" title="Hücre Değeri" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="12">
          {{ d_showWTableCellData.text }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WTable
} from '@/components/widgets/WTable';
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import Datepicker from 'wisdom-vue-datepicker';
import moment from 'moment';
import PatientService from '@/services/patient';
import LogService from '@/services/log';
import HospitalService from '@/services/hospital';
import UserService from '@/services/users';
import { DateFunctions } from '@/services/public/date_functions';
import store from '@/store';
import {
  default as router
} from '@/router';
import {
  default as WTopMenu
} from '@/components/widgets/WTopMenu';

export default {
  name: 'RequestLog',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  components: {
    Modal,
    WTopMenu,
    Datepicker,
    LineView,
    ScatterView,
    WTable,
    BarView
  },
  data () {
    return {
      d_totalLogCount: 0,
      d_searchTextTimeout: 0,
      d_showSearchSettings: false,
      d_showTextFilter: false,
      d_menuData: {
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('@/icon/2979152.png'),
          'value': 'general',
          'list': [{
            'icon': '',
            'label': 'Filtre',
            'value': 'filter',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Ayarlar',
              'icon': require('@/icon/1584256.png'),
              'label': '',
              'value': 'f_showSearchSettings',
              'click': this.f_showSearchSettings
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Filtreleme',
              'icon': require('@/icon/2447096.png'),
              'label': '',
              'value': 'f_showTextFilter',
              'click': this.f_showTextFilter
            }]
          }, {
            'icon': '',
            'label': 'Güncelle',
            'value': 'refresh',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Verileri Güncelle', // save
              'icon': require('@/icon/615577.png'),
              'label': '',
              'value': 'f_getLogs',
              'click': this.f_getLogs
            }]
          }]
        }, {
          'label': 'Liste',
          'icon': require('@/icon/1936797.png'),
          'value': 'list',
          'list': [{
            'icon': '',
            'label': 'Liste',
            'value': 'list',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Liste Görünümü',
              'icon': require('@/icon/1936797.png'),
              'label': '',
              'value': 'f_changeTabToTable',
              'click': this.f_changeTabToTable
            }]
          }]
        },{
          'label': 'İstatistik',
          'icon': require('@/icon/1053029.png'),
          'value': 'statistics',
          'list': [{
            'icon': '',
            'label': 'İstatistik',
            'value': 'statistics',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Sayısal Görünüm',
              'icon': require('@/icon/1053029.png'),
              'label': '',
              'value': 'f_changeTabToCount',
              'click': this.f_changeTabToCount
            }]
          }]
        },{
          'label': 'Grafik',
          'icon': require('@/icon/3082135.png'),
          'value': 'graphic',
          'list': [{
            'icon': '',
            'label': 'Grafik',
            'value': 'graphic',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Grafiksel Görünüm',
              'icon': require('@/icon/3082135.png'),
              'label': '',
              'value': 'f_changeTabToChart',
              'click': this.f_changeTabToChart
            }]
          }]
        }, {
          'label': 'Yardım',
          'icon': require('@/icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('@/icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      },
      chartSize: { 'height': 250, 'width': 750 },
      d_showChartView: true,
      d_requestCountCharts: [],
      selected_general_tab: 'request_table',
      d_logResultPageData: { 'current': 1, 'perpage': 100 },
      d_showMode: true,
      moment: moment,
      store: store,
      user: {},
      d_searchingData: { 'starting_at': '', 'ending_at': '', 'user_type': ['user'] },
      d_searchText: '',
      d_requestList: [],
      d_requestListColumns: [{
        value: "date",
        label: "Tarih"
      }, {
        value: "username",
        label: "Kullanıcı Adı"
      }, {
        value: "token",
        label: "Access Token"
      }, {
        value: "uri",
        label: "Uri"
      }, {
        value: "long_uri",
        label: "Uzun Uri"
      }, {
        value: "method",
        label: "Metod"
      }, {
        value: "remote_ip",
        label: "IP"
      }],
      
      d_selectedCells: [],
      d_showWTableCellData: { 'text': '', 'show': false }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let today = new Date();
    this.d_searchingData.starting_at = moment(today).format('YYYY-MM-DD');
    this.d_searchingData.ending_at = this.d_searchingData.starting_at;
    if (this.user.permissions_result && this.user.permissions_result.indexOf('log_request_show-all-hospitals') === -1) {
      this.f_getAllUsers();
    }
    this.chartSize.width = window.innerWidth;
  },
  mounted: function () {
    this.f_getLogs();
    this.$options.sockets.onmessage = function (message) {
      if (message.data) {
        let socket_data = JSON.parse(message.data);
        if (socket_data && socket_data.data) {
          this.d_requestList.splice(0, 0, socket_data.data);
          this.f_createRequestChartCounts();
          this.$forceUpdate();
        }
      }
    };
  },
  methods: {
    f_showWTableCellData: function (row, col_value) {
      this.d_showWTableCellData.text = row[col_value];
      this.d_showWTableCellData.show = true;
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selectedCells.indexOf(select) === -1) {
          this.d_selectedCells.push(select);
        } else {
          this.d_selectedCells.splice(this.d_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.logListColumns) {
          let y = row_ind.toString() + '-' + c.toString();
          this.d_selectedCells.push(y);
        }
      } else if (type === 'col') {
        for (let r in this.d_dataset) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_dataSetStyle: function (x) {
      let height = window.innerHeight - x;
      let overflow_x = 'auto';
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.d_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_showSearchSettings: function () {
      this.d_showSearchSettings = true;
    },
    f_showTextFilter: function () {
      this.d_showTextFilter = true;
    },
    f_changeTabToTable: function () {
      this.selected_general_tab = 'request_table';
    },
    f_changeTabToCount: function () {
      this.selected_general_tab = 'request_count';
    },
    f_changeTabToChart: function () {
      this.selected_general_tab = 'request_chart';
    },
    f_showChartWithTimeOut: function () {
      this.d_showChartView = false;
      setTimeout(function () {
        this.d_showChartView = true;
      }.bind(this), 500)
    },
    f_getLogs: function (keep_page = false) {
      if (this.d_searchingData.user_type.length === 0) {
        this.d_searchingData.user_type = ['user', 'not_user'];
      }
      this.d_requestList = [];
      this.d_searchingData.starting_at = this.d_searchingData.starting_at;
      this.d_searchingData.ending_at = this.d_searchingData.ending_at;
      if (this.f_calculateDayDiff(this.d_searchingData.starting_at, this.d_searchingData.ending_at)) {
        if (!keep_page) {
          this.d_logResultPageData['current'] = 1;
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Liste hazırlanıyor lütfen bekleyiniz' } });
        let service_data = {
          'starting_at': this.d_searchingData.starting_at,
          'ending_at': this.d_searchingData.ending_at,
          'user_type': this.d_searchingData.user_type,
          'pagination': this.d_logResultPageData,
          'search_text': this.d_searchText,
          'selected_general_tab': this.selected_general_tab
        }
        this.d_showMode = false;
        LogService.getRequestLogs(service_data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_totalLogCount = resp.data.result.log_count;
              this.d_requestList = resp.data.result.log_list;
            } else {
              alert('error', resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.d_showMode = true;
            if (this.selected_general_tab == 'request_chart' || this.selected_general_tab == 'request_count') {
              this.f_createRequestChartCounts();
            }
          });
      } else {
        alert('Lütfen tarih aralığı seçimi yaparken başlangıç ve sonraki tarihleri uygun seçiniz.');
      }
    },
    f_createRequestChartCounts: function () {
      this.d_requestCountCharts = [];
      let chart_type_list = ['username', 'uri', 'method', 'remote_ip', 'token'];
      for (let c in chart_type_list) {
        let color = this.f_calculateRGBA();
        let chart_type = chart_type_list[c];
        let chart_data = {
          "height": 250,
          "width": 750,
          "type": "bar",
          "labels": [],
          "datasets": [{
            "borderColor": "rgba(6, 149, 232, 1)",
            "data": [],
            "backgroundColor": color,
            "label": chart_type,
            'count': 0
          }],
          "name": chart_type
        };
        for (let i in this.d_requestList) {
          let lbl = this.d_requestList[i][chart_type];
          if (chart_data.labels.indexOf(lbl) === -1) {
            chart_data.labels.push(lbl);
            chart_data.datasets[0].data.push(0);
          }
          chart_data.datasets[0].data[chart_data.labels.indexOf(lbl)] += 1;
        }
        let object_list = [];
        for (let i in chart_data.labels) {
          object_list.push({ 'count': chart_data.datasets[0].data[i], 'label': chart_data.labels[i] });
        }
        let asc_or_desc = -1;
        let param = 'count';

        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const param_a = a[param];
          const param_b = b[param];

          let comparison = 0;
          if (param_a > param_b) {
            comparison = 1;
          } else if (param_a < param_b) {
            comparison = -1;
          }
          return comparison * asc_or_desc;
        }
        object_list.sort(compare);
        chart_data.datasets[0].data = [];
        chart_data.labels = [];
        for (let i in object_list) {
          chart_data.labels.push(object_list[i].label);
          chart_data.datasets[0].data.push(object_list[i].count);
        }
        this.d_requestCountCharts.push(chart_data);
      }
      this.f_showChartWithTimeOut();
    },
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    },
    f_calculateDayDiff: function (first_date, second_date) {
      if (first_date === second_date) {
        return true;
      } else {
        if (DateFunctions.day_dif(first_date, second_date) > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    'd_searchText': function () {
      let text = this.d_searchText;
      this.d_searchTextTimeout = setTimeout(function () {
        if (text === this.d_searchText) {
          this.f_getLogs();
        }
      }.bind(this), 2000);
    },
    'd_logResultPageData.current': function () {
      this.f_getLogs(true);
    },
    'selected_general_tab': function () {
      this.f_getLogs(true);
    }
  }
};

</script>

<style type="text/css">
.s_tdCls {
  white-space: nowrap;
}
</style>
